import React, { useState, useCallback, useMemo } from "react";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

import './contactus-form.css'

const Form = (props) => {
    // For Recapture v3
    const recaptchaKey = process.env.REACT_APP_SECRET_NAME
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
    const [formData, setFormData] = useState({
      service: '',
      name: '',
      email: '',
      tel: '',
      nic1: '',
      nic2: ''
    });
    
    // Funtion to covert the file
    const base64Conv = (formDataFile) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const b64 = reader.result.replace(/^data:.+;base64,/, '');
          resolve(b64);
        };
        const file = formDataFile;
        reader.readAsDataURL(file);
      });
    };
  
    // Funtion to send email
    const sendEmail = () => {
      if (formData.service === 'TIN Registration' || formData.service === 'TIN Registration') {
        const nic1Base64Promise = base64Conv(formData.nic1);
        const nic2Base64Promise = base64Conv(formData.nic2);
    
        Promise.all([nic1Base64Promise, nic2Base64Promise])
        .then((results) => {
          const [nic1Base64, nic2Base64] = results;
          Email.send({
            Host : "smtp.elasticemail.com",
            Username : "info@taxobey.lk",
            Password : "E0D58FE761F1A2423C1189C0D77101B50161",
            To : "info@taxobey.lk",
            From : "info@taxobey.lk",
            Subject : "New request",
            Body : `
            Service:${formData.service}<br>
            Name:${formData.name}<br>
            Email:${formData.email}<br>
            Tel:${formData.tel}<br>
            `,
            Attachments : [
            {
              name : "nic1.png",
              data : nic1Base64,
            },
            {
              name : "nic2.png",
              data : nic2Base64,
            }]
          }).then((message) => {
            if (message == 'OK') {
                alert("Request has been sent")       
              } else {
                alert("Request failed, please try again")       
              }
            });
        })
        .catch((error) => {
          console.error('Error converting to base64:', error);
        })
      } else {
        Email.send({
          Host : "smtp.elasticemail.com",
          Username : "info@taxobey.lk",
          Password : "E0D58FE761F1A2423C1189C0D77101B50161",
          To : "info@taxobey.lk",
          From : "info@taxobey.lk",
          Subject : "New request",
          Body : `
          Service:${formData.service}<br>
          Name:${formData.name}<br>
          Email:${formData.email}<br>
          Tel:${formData.tel}<br>
          `
        }).then((message) => {
          if (message == 'OK') {
            alert("Request has been sent")       
          } else {
            alert("Request failed, please try again");    
          }
        });
      }
    }
  
    // Funtion to verify the files
    const fileVerify = (uploadedFile, name) => {
      const allowedTypes = ['image/jpeg', 'image/png'];
      const maxSize = 5 * 1024 * 1024; // 5MB
  
      const uploadedFileType = uploadedFile.type
      const uploadedFileSize = uploadedFile.size
      // Check if the number of files exceeds the limit
      if (uploadedFileSize > maxSize) {
        alert(`File ${uploadedFile.name} exceeds the maximum size of 5MB.`);
        // check which uploader
        if (name == 'nic1') {
          setFormData({
            nic1: ''
          })     
        } else {
          setFormData({
            nic2: ''
          })     
        }
        return (false);
      }
      
      // Check each file for type and size restrictions
      if (!allowedTypes.includes(uploadedFileType)) {
        alert(`File ${uploadedFile.name} is not a valid image file.`);
        if (name == 'nic1') {
          setFormData({
            nic1: ''
          })     
        } else {
          setFormData({
            nic2: ''
          })     
        }
        return (false);
      }
  
      return(true)
    }
  
    // Handle the file upload
    const handleFileChange = (uploadedFile) => {
      const { name, files } = uploadedFile.target;
      // Do file verifications
      if (fileVerify(files[0], name) == true) {
        // set the file
        setFormData({
          ...formData,
          [name]: files[0] // Assuming you only allow selecting one file
        });
      }
    }
  
    // Handle the form field change
    const handleFormChange = (event) => {       
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value
        });
    };
    
    // Handle the form submit
    const handleFormSubmit = (e) => {
      // prevent reload
      e.preventDefault();
  
      // send the form data across
      sendEmail()
  
      // reset the form
      setFormData({
        service: '',
        name: '',
        email: '',
        tel: '',
        nic1: '',
        nic2: ''
      })
  
      // Refresh the toekn
      setRefreshReCaptcha(!refreshReCaptcha);
    }

    // Verify the token
    const onVerify = useCallback((newToken) => {
      // Update token state only if it's different from the current token
      setToken((prevToken) => (prevToken !== newToken ? newToken : prevToken));
    }, []);

    // The componenent to verify
    const googleReCaptchaComponent = useMemo(() => (
      <GoogleReCaptcha
          className="google-recaptcha-custom-class"
          onVerify={onVerify}
          refreshReCaptcha={refreshReCaptcha}
      />
    ), [onVerify, refreshReCaptcha]);
  
    return (
      <div className="form-container01">
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
          <form onSubmit={handleFormSubmit}>
            <div className='request-input'>
                <select value={formData.service} name="service" onChange={handleFormChange} required>
                  <option value='' disabled>Choose service</option>
                  <option name='tin-registration'>TIN Registration</option>
                  <option name='vat-registration'>VAT Registration</option>
                  <option name='tax-consultant'>Tax Consultant</option>
                  <option name='small-business'>Small Business</option>
                  <option name='small-business-plus'>Small Business Plus</option>
                  <option name='small-business-advanced'>Small Business Advanced</option>
                  <option name='employment-income-or-investment-income-only'>Employment Income Or Investment Income Only</option>
                  <option name='employment-income-&-investment-income-only'>Employment Income & Investment Income Only</option>
                  <option name='investment-income-/-employment-income/business-income-(without-financial-statements)'>Investment Income / Employment Income/Business Income (Without Financial Statements)</option>
                  <option name='investment-income-/-employment-income/business-income-with-financial-statements)'>Investment Income / Employment Income/Business Income With Financial Statements</option>
                  <option name='custom'>Custom</option>
                </select>
                <input type='text' placeholder='Name' name='name' value={formData.name} onChange={handleFormChange} required></input>
                <input type='email' placeholder='Email' name='email' value={formData.email} onChange={handleFormChange} required></input>
                <input type='tel' placeholder='Tel' name='tel' value={formData.tel} onChange={handleFormChange} required></input>
                {
                  (formData.service === "TIN Registration") && (
                    <div className='request-file-upload'>
                      <span className="request-span">*Accepts jpeg & PNG files only</span>
                      <label>Upload NIC front copy</label>
                      <input accept="image/jpeg, image/png" type='file' name='nic1' onChange={handleFileChange} required></input>
                      <label>Upload NIC back copy</label>
                      <input accept="image/jpeg, image/png" type='file' name='nic2' onChange={handleFileChange} required></input>
                    </div>
                  )
                }
            </div>
              {googleReCaptchaComponent}
            <button type="submit" className="formSubmitButton">Submit</button>
          </form>
        </GoogleReCaptchaProvider>
      </div>
    )
  }
  
  export default Form