import React, { useMemo } from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer'
import './contact.css'
import ContactUsForm from '../components/contactus-form'

const Contact = (props) => {
  const mForm = useMemo(() => <ContactUsForm/>, [])
  return (
    <div className="contact-container">
      <Helmet>
        <title>Contact - TaxObey</title>
        <meta
          name="description"
          content="Title: Taxobey: Maximize Tax Returns!\nMeta Description: Get expert tax solutions with Taxobey! From planning to resolution, optimize returns hassle-free. Contact now!"
        />
        <meta property="og:title" content="Contact - TaxObey" />
        <meta
          property="og:description"
          content="Title: Taxobey: Maximize Tax Returns!\nMeta Description: Get expert tax solutions with Taxobey! From planning to resolution, optimize returns hassle-free. Contact now!"
        />
      </Helmet>
      <Header rootClassName="header-root-class-name2"></Header>
      <div className="contact-hero">
        <div className="heroContainer contact-hero1">
          <div className="contact-container01">
            <h1 className="contact-hero-heading heading1">
              Get In Touch With Us TODAY!
            </h1>
          </div>
        </div>
      </div>
      <div className="contact-container02">
        <div className="contact-container06">
          <button type="button" className="button">
            Button
          </button>
        </div>
        <div className="contact-container07">
          <div className="contact-container08">
            <div>
              <span className="contact-email">Email: </span>
              <a href="mailto:info@.com"><span className="contact-text">info@taxobey.lk</span></a>
            </div>
            <div className="contact-container09">
              <span className="contact-text">Tel:+94 707 876 580 </span>
            </div>
            <div className="contact-container09">
              <a className='whatsapp-link' href="https://wa.me/94707876580">WhatsApp:+94 707 876 580 </a>
            </div>
          </div>
          <div className='form-container02'>
            <ContactUsForm/>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  )
}

export default Contact
