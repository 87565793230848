import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './style.css'
import Home from './views/home'
import Contact from './views/contact'
import TaxServices from './views/tax-services'
import AccountingServices from './views/accounting-services'
import About from './views/about'
// import Datarequest from './views/request'
// add to route if need to navigate <Route component={Datarequest} exact path="/request" />
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tax-services" element={<TaxServices />} />
        <Route path="/accounting-services" element={<AccountingServices />} />
        <Route path="/about" element={<About />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

export default App;

